/* ==========================================================================
 *  Main Section 100% height
 * ======================================================================= */


function fn_parts_navigation_scroll_resize(){
    if ($('.navigation').length){
        if ($(window).scrollTop() > 1 && $(window).width() > 768) {
            $('.navigation').addClass("navigation-scroll");
            fn_checkLogo();
        } else {
            $('.navigation').removeClass("navigation-scroll");
            fn_checkLogo();
        }
    }
}

function fn_checkLogo(){
    if ($(window).width() < 768) {
        if ($('.header-style-1').length) {
            $('.header-style-1 .logo img.active').attr('src', 'public/img/expandeco-logo-inverse-2.svg');
        }
    } else {
        if ($('.header-style-1').length) {
            if ($('.navigation-scroll').length) {
                $('.header-style-1 .logo img.active').attr('src', 'public/img/expandeco-logo-inverse-2.svg');
            } else {
                $('.header-style-1 .logo img.active').attr('src', 'public/img/expandeco-logo.svg');
            }
        }
    }
}

