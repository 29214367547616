//====================================================
//  Initialization Navigation
//====================================================

function fn_components_fullscreen_navigation(){
	if ($('.navigation').length){
		$('.nav-trigger').on('click', function(e){
	        e.preventDefault();
	        $('.navigation').toggleClass('nav-active');
	        $('.navigation').toggleClass('overflow-hidden');
	    });
	}
}

function fn_checkNavigationTrigger(){
    (function($){
        if($(window).width() > 768 && $('.navigation').hasClass('nav-active')){
            $('.navigation').toggleClass('nav-active');
        }
    })(jQuery);
}