/* ==========================================================================
 *  Thumbnail product change on hover 
 * ======================================================================= */
function fn_components_category_mobile(){
	if ($('.js-side-panel-trigger').length){
		$('.js-side-panel-trigger').on('click', function(e){
	        e.preventDefault();
	        $(this).closest('.js-side-block').toggleClass('active');
	    });
	}
}
