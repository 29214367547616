// --- LAYOUT, COMPONENTS, PARTS --- //
$(function(){
	// ---  LAYOUT --- //
	//fn_layout_main();

	// ---  COMPONENTS --- //
	fn_components_fullscreen_navigation();
	fn_components_google_map();
	fn_components_modal();
	fn_components_category_mobile();
	fn_components_gallery();
	fn_components_slider();
	fn_components_select();
	fn_components_tabs(); 
	fn_components_map(); 
	fn_components_accordion();


	// ---  PARTS --- //
	fn_parts_navigation_scroll_resize();
	fn_parts_dropdown();
	fn_parts_open_info();
	fn_parts_close_block();
	fn_parts_more_text();
});


// --- MISC --- //
$(function () {
	nl_fn_scroll_to();
});

// --- EVENTS --- //
$(window).resize(function(){
	fn_checkNavigationTrigger();
	fn_parts_navigation_scroll_resize();
});

$(document).scroll(function () {
	fn_parts_navigation_scroll_resize();
});


$(window).load(function () {
	fn_parts_dots();
});
