//====================================================
//  Initialization Accordion
//====================================================
function fn_components_accordion(){
	if ($('.accordion').length){
	    $('.accordion-trigger').click(function () {
	        var inst = $(this);
	        var thisItem = inst.closest('.item');
	    	var content = '.accordion-content';
	    	var faqWrapper = inst.closest('.accordion')

	        if (thisItem.hasClass('active')) {
	            thisItem.toggleClass('active');
	        } else {
	            faqWrapper.find('.item.active').removeClass('active');
	            faqWrapper.find(content).each(function () {
	                $(this).stop().slideUp();
	            });
	            inst.closest('.item').toggleClass('active');
	        }
	        inst.next(content).stop().slideToggle();
	        return false;
	    });
	}
}