//====================================================
//  Initialization Select
//====================================================
function fn_components_select(){
	if ($('.select').length){
		$(".select").select2({
			minimumResultsForSearch: Infinity,
			placeholder: function(){
				$(this).data('placeholder');
			}
		});
	}
}