/* ==========================================================================
 *  Dropdown toggle
 * ======================================================================= */
function fn_parts_dropdown(){
	if ($('.js-dropdown-toggle').length){
          $('.js-dropdown-toggle').on('click', function () {
          	if ($(this).closest('.js-dropdown-block').hasClass('active')){
	        	$(this).closest('.js-dropdown-block').toggleClass('active');
          	} else {
          		$('.js-dropdown-block.active').removeClass('active');
	        	$(this).closest('.js-dropdown-block').toggleClass('active');
          	}
	    });
	}
}