/* ==========================================================================
 *  Open info block
 * ======================================================================= */
function fn_parts_open_info(){
	if ($('.js-dropdown-trigger').length){
          $('.js-dropdown-trigger').on('click', function () {
			if ($(window).width() < 768){
	        	$(this).toggleClass('active');
	        	$(this).find('.perex').slideToggle('');
	        }
	    });
	}
}