//====================================================
//  Initialization Accordion
//====================================================
function fn_components_gallery(){
	if ($('.gallery').length){
         $('.gallery').lightGallery({
            thumbnail: true,
            selector: '.gallery-item',
            subHtmlSelectorRelative: true
        })
	}
}