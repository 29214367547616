//====================================================
//  Initialization Slider
//====================================================
function fn_components_slider(){
	fn_partners_slider();
	fn_gallery_slider();
	fn_gallery_2_slider();
}



function fn_partners_slider(){
	if ($('.js-slider-partners').length) {
		$('.js-slider-partners').slick({
			infinite: true,
			dots: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2000,
			cssEase: 'linear',
			speed: 500,
			variableWidth: false,
			mobileFirst: true,
			// nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-thin-right"></i></span>',
			// prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-thin-left"></i></span>'
			responsive: [
				{
				  breakpoint: 1440,
				  settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 1220,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 990,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 760,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 550,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				  }
				},
				{
				  breakpoint: 250,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				  }
				}
			]
		});
	}    
}

function fn_gallery_slider(){
    if ($('.js-slider-gallery').length) {
        $('.js-slider-gallery').slick({
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            speed: 300,
            variableWidth: false,
            mobileFirst: true,
            nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
            prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-right"></i></span>',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                }
            ]
        });
    }
}

function fn_gallery_2_slider(){
    if ($('.js-slider-gallery-2').length) {
        $('.js-slider-gallery-2').slick({
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            speed: 300,
            variableWidth: false,
            mobileFirst: true,
            nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-right"></i></span>',
            prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-right"></i></span>',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                }
            ]
        });
    }
}
