//====================================================
//  Initialization Map
//====================================================
function fn_components_map() {
    $('.address-map .map-block').each(function() {
        var inst = $(this);
        var id = inst.attr('id');
        var langitude = inst.data('langitude');
        var longitude = inst.data('longitude');
        fn_components_map_init(id, langitude, longitude);
    });
}


function fn_components_map_init(mapId, langitude, longitude) {
    var map
    var mapOptions = {
        zoom: 8,
        panControl: false,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
        //draggable: false,
        center: new google.maps.LatLng(langitude, longitude),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{
                "featureType": "administrative.locality",
                "elementType": "all",
                "stylers": [{
                        "hue": "#2c2e33"
                    },
                    {
                        "saturation": 7
                    },
                    {
                        "lightness": 19
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [{
                        "hue": "#ffffff"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [{
                        "hue": "#ffffff"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [{
                        "hue": "#bbc0c4"
                    },
                    {
                        "saturation": -93
                    },
                    {
                        "lightness": 31
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [{
                        "hue": "#bbc0c4"
                    },
                    {
                        "saturation": -93
                    },
                    {
                        "lightness": 31
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels",
                "stylers": [{
                        "hue": "#bbc0c4"
                    },
                    {
                        "saturation": -93
                    },
                    {
                        "lightness": -2
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{
                        "hue": "#e9ebed"
                    },
                    {
                        "saturation": -90
                    },
                    {
                        "lightness": -8
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [{
                        "hue": "#e9ebed"
                    },
                    {
                        "saturation": 10
                    },
                    {
                        "lightness": 69
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [{
                        "hue": "#e9ebed"
                    },
                    {
                        "saturation": -78
                    },
                    {
                        "lightness": 67
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            }
        ]
    };
    map = new google.maps.Map(document.getElementById(mapId), mapOptions);

    var gmarkers = [];
    var bounds = new google.maps.LatLngBounds();
    var imageSrc = "public/img/map-marker.png";

    var defaultIcon = new google.maps.MarkerImage(
        imageSrc,
        null,
        null,
        null,
        new google.maps.Size(34, 47)
    );

    if (mapId == "map-place-cz"){
        var markers = {
            "count": 4,
            "marker": [{
                "id": '1',
                "type": '',
                "icon": 'public/img/map-marker.png',
                "name": "<span class='title'>Sídlo společnosti, výroba</span><span class='text'>uničovská, mesto1</span>",
                "longitude": 49.2020489,
                "latitude": 16.5079212
            }, {
                "id": '2',
                "type": '',
                "icon": 'public/img/map-marker.png',
                "name": "<span class='title'>Sídlo společnosti, výroba</span><span class='text'>uničovská, mesto2</span>",
                "longitude": 50.0595854,
                "latitude": 14.3255427
            }, {
                "id": '3',
                "type": '',
                "icon": 'public/img/map-marker.png',
                "name": "<span class='title'>Sídlo společnosti, výroba</span><span class='text'>uničovská, mesto3</span>",
                "longitude": 49.8197203,
                "latitude": 18.1673552
            }, {
                "id": '4',
                "type": 'active',
                "icon": 'public/img/map-marker.png',
                "name": "<span class='title'>Sídlo společnosti, výroba</span><span class='text'>uničovská, šumperk</span>",
                "longitude": 49.9771231,
                "latitude": 16.9244937
            }]
        }
    } else {
        var markers = {
            "count": 2,
            "marker": [{
                "id": '1',
                "type": '',
                "icon": 'public/img/map-marker.png',
                "name": "<span class='title'>Sídlo společnosti, výroba</span><span class='text'>uničovská, mesto1</span>",
                "longitude": 48.1446681,
                "latitude": 17.1079017
            }, {
                "id": '2',
                "type": '',
                "icon": 'public/img/map-marker.png',
                "name": "<span class='title'>Sídlo společnosti, výroba</span><span class='text'>uničovská, mesto2</span>",
                "longitude": 48.7058781,
                "latitude": 21.2609258
            }]
        }
    }
 
    for (var i = 0; i < markers.count; ++i) {
        var latLng = new google.maps.LatLng(markers.marker[i].longitude, markers.marker[i].latitude)


        var marker = new google.maps.Marker({
            position: latLng,
            draggable: false,
            icon: markers.marker[i].icon,
            id: markers.marker[i].id,
            name: markers.marker[i].name
        });

        var infoWindow = new google.maps.InfoWindow({
                pixelOffset: new google.maps.Size(-95, 43),
                maxWidth: 200
            }),
            marker, i;

        google.maps.event.addListener(marker, 'click', function(e) {
            if (this.id.length) {
                infoWindow.setContent('<p>' + this.name + '</p>');
                infoWindow.open(map, this);
                $('.window-box-block .contact-window.active').removeClass('active');
                $('.window-box-block .contact-window[data-marker="' + this.id + '"]').addClass('active');
                $('.gm-style-iw').parent().addClass('window-block');
            }
        });

        bounds.extend(marker.position);
        marker.setMap(map);
        gmarkers.push(marker);


        if (markers.marker[i].type == 'active') {
            infoWindow.setContent('<p>' + markers.marker[i].name + '</p>');
            infoWindow.open(map, marker);
            $('.window-box-block .contact-window.active').removeClass('active');
            $('.window-box-block .contact-window[data-marker="' + markers.marker[i].id + '"]').addClass('active');
            setTimeout(function() {
                $('.gm-style-iw').parent().addClass('window-block');
            }, 1000);
        }

    }

    map.fitBounds(bounds);

}