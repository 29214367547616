//====================================================
//  Initialization Tabs
//====================================================
function fn_components_tabs(){
	fn_tabs();
}

function fn_tabs(){
	if ($('.tabs').length){
		$( ".tabs .item" ).click(function() {
	        var inst = $(this);
	        var thisId = inst.attr('id');
	        var thisTabs = $(this).closest('.tab-block');

	        if(thisId == 'undefined'){
	        	return false;
	        }
	        
	        if (!inst.hasClass('active')){
	            thisTabs.find( ".tabs .item" ).each(function(){
	                $(this).closest('.item').removeClass('active');
	            });
	            $(this).closest('.item').addClass('active');
	            thisTabs.find(" .tabs-content .item-block" ).each(function(){
	                $(this).removeClass('active');
	            });
	            thisTabs.find('.tabs-content .item-block[data-id="'+ thisId + '"]').addClass('active');
	        }
	    });
	}
}

